/** @jsx jsx */
import { jsx } from '@emotion/react';

export function FilterActiveFalseSVG(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      css={{ width: '100%', height: 'auto' }}
      {...props}
    >
      <path d="M12 18.88C12.0181 19.0313 12.0014 19.1847 11.9511 19.3286C11.9009 19.4724 11.8184 19.6029 11.71 19.71C11.5226 19.8963 11.2692 20.0008 11.005 20.0008C10.7408 20.0008 10.4874 19.8963 10.3 19.71L6.30001 15.71C6.18841 15.6028 6.10322 15.4711 6.05117 15.3253C5.99911 15.1795 5.9816 15.0237 6.00001 14.87V9.75L1.21001 3.62C1.04762 3.41153 0.974343 3.14726 1.0062 2.88493C1.03805 2.6226 1.17244 2.38355 1.38001 2.22C1.55595 2.07884 1.77444 2.00131 2.00001 2H16C16.2256 2.00131 16.4441 2.07884 16.62 2.22C16.8276 2.38355 16.962 2.6226 16.9938 2.88493C17.0257 3.14726 16.9524 3.41153 16.79 3.62L12 9.75V18.88V18.88ZM4.00001 4L8.00001 9.06V14.58L10 16.58V9.05L14 4H4.00001ZM13 16L18 21L23 16H13Z" />
    </svg>
  );
}
export function FilterActiveTrueSVG(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      css={{ width: '100%', height: 'auto' }}
      {...props}
    >
      <path d="M11 11L16.76 3.62C16.9224 3.41153 16.9957 3.14726 16.9638 2.88493C16.932 2.6226 16.7976 2.38355 16.59 2.22C16.4222 2.08505 16.2152 2.00789 16 2H2.00001C1.77444 2.00131 1.55595 2.07884 1.38001 2.22C1.17244 2.38355 1.03805 2.6226 1.0062 2.88493C0.974343 3.14726 1.04762 3.41153 1.21001 3.62L7.00001 11V16.87C6.98192 17.0213 6.99864 17.1747 7.0489 17.3186C7.09916 17.4624 7.18163 17.5929 7.29001 17.7L9.29001 19.7C9.47737 19.8863 9.73082 19.9908 9.99501 19.9908C10.2592 19.9908 10.5126 19.8863 10.7 19.7C10.8102 19.5938 10.8945 19.4638 10.9465 19.3199C10.9985 19.176 11.0168 19.0221 11 18.87V11ZM13 16L18 21L23 16H13Z" />
    </svg>
  );
}

export function FilterIconSVG({ hasActiveFilters, ...props }) {
  return hasActiveFilters ? (
    <FilterActiveTrueSVG {...props} />
  ) : (
    <FilterActiveFalseSVG {...props} />
  );
}

export default FilterIconSVG;
